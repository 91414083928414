<template>
  <Container>
    <div class="flex mb-5 justify-between">
      <div v-for="(featuredSeller, index) in featuredSellerList" :key="featuredSeller+index" class="featured-seller-content">
        <strong class="block font-semibold text-sm">Featured Seller {{ index+1 }}</strong>
        <div class="my-5 flex">
          <input type="text" class="border border-black h-10 w-48 px-4 text-sm admin-input" placeholder="파트너 ID" v-model="featuredSeller.partnerId">
          <button
            class="
              w-30
              h-10
              ml-2
              border border-black
              shadow
              flex
              items-center
              justify-center
              admin-button
            "
            @click="handleRegister(featuredSeller)"
          >
            <IconBase>
              <CheckCircleIcon></CheckCircleIcon>
            </IconBase>
            <span class="ml-2">등록하기</span>
          </button>
        </div>
        <div class="multiple-product-item">
          <div class="image-module multiple">
            <div class="image-module__link">
              <figure v-for="(num, index) in 4" :key="index" class="image">
                <img :src="featuredSeller.productImageList[index] || '/images/default-img-blue.png'" class="image__content cover" alt />
              </figure>
              <!--        5개 이상일때 보이기-->
              <div class="image-module-more" v-if="featuredSeller.productCount && featuredSeller.productCount > 4">
                + {{ featuredSeller.productCount - 4 }}
              </div>
            </div>
          </div>
          <div
            class="seller-info cursor-pointer"
            @click="handleClickProfile(featuredSeller.partnerId)"
          >
            <div class="seller-info-profile">
              <img :src="featuredSeller.profileImage || '/images/default-img-blue.png'" alt="" class="profile__img">
            </div>
            <div class="seller-info-content">
              <div class="seller-info-name-label">
                <strong class="seller-info__name">{{ featuredSeller.companyName }}</strong>
              </div>
              <div class="seller-info-category-area">
                <mark v-for="(num, index) in featuredSeller.categoryList.length > 3 ? 3 : featuredSeller.categoryList.length" :key="index" class="seller-info__category">
                  {{ featuredSeller.categoryList[index] }}
                </mark>
                <mark v-if="featuredSeller.categoryList && featuredSeller.categoryList.length > 3" class="seller-info__category number">
                  +{{ featuredSeller.categoryList.length - 3 }}
                </mark>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- seller list -->
    <ListFilter
      :params="filterParams"
      :handleChangeFilterParams="handleChangeFilterParams"
      :onClickSearch="handleClickSearch"
    />
    <ListTable
      :sellers="sellerList.data"
      @refresh="fetchSellerList"
    ></ListTable>

    <div class="mt-10">
      <Pagination
        :initialPage="filterParams.page"
        :pageSize="parseInt(filterParams.pageSize)"
        :onChange="handleChangePage"
        :onChangePageSize="handleChangePageSize"
        :total="sellerList.total"
      ></Pagination>
    </div>
  </Container>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, watch, reactive } from 'vue';
import { useRoute } from 'vue-router';
import Container from '@/components/Container.vue';
import Pagination from '@/components/Pagination/index.vue';
import router from '@/router/index';
import ListFilter from './ListFilter.vue';
import ListTable from './ListTable.vue';
import partnerAPI from '@/service/partnerAPI';
import IconBase from '@/components/icons/IconBase.vue';
import CheckCircleIcon from '@/components/icons/CheckCircleIcon.vue';
import getServerErrorMessage from '@/utils/getServerErrorMessage';

export type FeaturedSellerListParams = {
  keyword?: string;
  page: number;
  pageSize: number;
};

export default defineComponent({
  name: 'FeatureSeller',
  components: {
    Container,
    ListFilter,
    ListTable,
    Pagination,
    IconBase,
    CheckCircleIcon
  },
  props: {},
  setup() {
    const route = useRoute();
    const query = route.query;
    const featuredSellerList = ref([])

    const handleRegister = async (featuredSeller) => {
      const featuredSellerId = featuredSeller.featuredSellerId
      const partnerId = featuredSeller.partnerId
      try {
        const { data } = await partnerAPI.adminFeaturedSeller.adminFeaturedSellerUpdate({
          featuredSellerId,
          partnerId
        })
        alert(data.message)
        fetchFeaturedSellerList()
      }catch (e) {
        alert(getServerErrorMessage(e));
      }
    }


    const filterParams = reactive<FeaturedSellerListParams>({
      keyword: (query.keyword as string) || '',
      page: Number(query.page) || 0,
      pageSize: Number(query.pageSize) || 10,
    });

    const sellerList = ref({
      success: true,
      data: [],
      message: '',
      total: 0,
    });

    const handleChangeFilterParams = (key: string, value: any) => {
      filterParams[key] = value;
    };

    const handleChangePage = (page: number) => {
      filterParams.page = page - 1;
      executeSearch();
    };
    const handleChangePageSize = (pageSize: number) => {
      filterParams.page = 0;
      filterParams.pageSize = pageSize;
      executeSearch();
    };
    const handleClickSearch = () => {
      console.log('베르나');
      filterParams.page = 0;
      executeSearch();
    };

    /**
     * 검색 실행
     */
    const executeSearch = () => {
      //fetchSellerList();
      router.push({
        path: window.location.pathname,
        query: {
          keyword: filterParams.keyword || '',
          page: filterParams.page || 0,
          pageSize: filterParams.pageSize || 10,
        },
      });
    };

    const getAppliedFilterParams = () => {
      return {
        keyword: filterParams.keyword,
        page: filterParams.page,
        pageSize: filterParams.pageSize,
      }
    }

    const fetchFeaturedSellerList = async () => {
      try {
        const { data } = await partnerAPI.adminFeaturedSeller.adminFeaturedSellerAll();
        featuredSellerList.value = data.data as any;
      } catch ({ response }) {
        console.error(response.data);
        alert(response.data.message);
      }
    }
    const fetchSellerList = async () => {
      try {
        const { data } = await partnerAPI.adminFeaturedSeller.adminFeaturedSellerPartnerList(
          getAppliedFilterParams()
        );
        sellerList.value = data as any;
      } catch ({ response }) {
        console.error(response.data);
        alert(response.data.message);
      }
    };


    onMounted(() => {
      fetchSellerList()
      fetchFeaturedSellerList()
    });

    watch([filterParams], (newFilter, newIsFilterOn) => {
      console.log('watch filter', newFilter, newIsFilterOn);
      fetchSellerList();
    });

    const handleClickProfile = (partnerId) => {
      console.log('dd')
      window.open(
          `/etc/partner/${partnerId}`,
          '',
          'width=1560, height=800, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=no, location=no'
      )
    }

    return {
      handleRegister,
      filterParams,
      sellerList,
      featuredSellerList,
      handleChangePage,
      handleChangePageSize,
      handleClickSearch,
      executeSearch,
      handleChangeFilterParams,
      fetchSellerList,
      handleClickProfile
    };
  },
});
</script>

<style lang="scss">
.featured-seller-content {
  width: 318px;
}
.multiple-product-item {
  position: relative;
  width: 318px;
  .image-module {
    position: relative;
    height: 228px;
    .image-module__link {
      overflow: hidden;
      position: relative;
      display: flex;
      flex-wrap: wrap;
    }
    .image {
      display: block;
      position: relative;
      height: 114px;
      width: 159px;
      .image__content {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        -o-object-fit: cover;
        object-fit: cover;
        &:after {
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          background: rgba(0,0,0,.05);
          z-index: 2;
          content: "";
        }
      }
    }
  }
  .image-module-more {
    background: rgba(0,0,0,.5);
    color: #fff;
    font-size: 18px;
    font-weight: 800;
    line-height: 1.11;
    position: absolute;
    right: 0;
    bottom: 0;
    width: 50%;
    height: 50%;
    z-index: 11;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .seller-info {
    height: 90px;
    background: #fff;
    padding: 20px 7px 20px 14px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    &-profile {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      overflow: hidden;
      border: 1px solid #F1F1F5;
      box-sizing: border-box;
      .profile__img {
        object-fit: contain;
      }
    }
    &-name-label {
      display: flex;
      align-items: center;
    }
    &-content {
      width: calc(100% - 60px);
    }
    &__name {
      display: block;
      //max-width: calc(100% - 91px);
      max-width: calc(100% - 10px);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .seller-info-category-area {
      margin-top: 4px;
      display: flex;
    }
    &__category {
      color: rgba(0,0,0,.5);
      padding: 2px 3px;
      background: #d9d9d9;
      border-radius: 2px;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 400;
      line-height: 1.16;
      &.number {
        background: none;
        padding: 2px 0;
      }
      & + .seller-info__category {
        margin-left: 6px;
      }
    }
  }
  &__link {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }
}
</style>
