
import { defineComponent, PropType } from 'vue';
import { format } from 'date-fns';

export default defineComponent({
  name: 'ListTable',
  components: {
  },
  props: {
    sellers: Object as PropType<any>,
  },
  setup() {
    const handleClickRow = (partnerId) => {
      console.log('dd')
      window.open(
          `/etc/partner/${partnerId}`,
          '',
          'width=1560, height=800, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=no, location=no'
      )
    }

    return {
      format,
      handleClickRow,
    };
  },
});
