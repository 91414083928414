<template>
  <table
    v-if="sellers"
    class="ListTable table-fixed w-full text-center text-sm mt-6"
  >
    <thead class="bg-gray-200">
      <tr class="h-10">
        <th class="w-15 border-t border-black border-b">Partner ID</th>
        <th class="w-24 border-t border-black border-b">파트너 회사명</th>
        <th class="w-30 border-t border-black border-b">파트너회원ID(이메일)</th>
        <th class="w-20 border-t border-black border-b">대표전화</th>
        <th class="w-40 border-t border-black border-b">주소</th>
        <th class="w-20 border-t border-black border-b">판매상품</th>
        <th class="w-24 border-t border-black border-b">가입일</th>
      </tr>
    </thead>
    <tbody>
      <tr
        class="border-b border-grey-300 text-center cursor-pointer text-xs"
        v-for="(item, index) in sellers"
        @click="handleClickRow(item.partnerId)"
        :key="index"
      >
        <!-- memberId -->
        <td>
          <div class="ml-4">{{ item.partnerId }}</div>
        </td>

        <!-- 파트너 회사명 -->
        <td>
          <div class="flex items-center mt-1">
            <template v-if="item.companyName">
              <div
                  class="w-12 h-12 bg-gray-100 bg-cover rounded-full mr-1 flex-none"
                  :style="{
                      backgroundImage: `url(${item.profileImage})`,
                    }"
              ></div>
              <span class="text-xs text-left">{{ item.companyName }}</span>
            </template>
            <template v-else>
              -
            </template>
          </div>
        </td>

        <!-- 회원ID(이메일) -->
        <td>
          {{ item.email }}
        </td>

        <!-- 대표전화 -->
        <td>
          {{ item.csTelNo || '-' }}
        </td>

        <!-- 주소 -->
        <td>
          {{ item.address || '-'}}
        </td>

       <!-- 판매상품 -->
        <td>
          {{ item.productCount.toLocaleString() + '개' || '-'}}
        </td>

        <!-- 가입일 -->
        <td>
          {{ format(new Date(item.createdAt), 'yyyy/MM/dd HH:mm') || '-'}}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { format } from 'date-fns';

export default defineComponent({
  name: 'ListTable',
  components: {
  },
  props: {
    sellers: Object as PropType<any>,
  },
  setup() {
    const handleClickRow = (partnerId) => {
      console.log('dd')
      window.open(
          `/etc/partner/${partnerId}`,
          '',
          'width=1560, height=800, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=no, location=no'
      )
    }

    return {
      format,
      handleClickRow,
    };
  },
});
</script>

<style lang="scss" scoped>
.ListTable {
  tbody {
    tr {
    }
  }

  td {
    padding: 8px 10px;
  }
}
</style>
