
import { defineComponent, ref, onMounted, watch, reactive } from 'vue';
import { useRoute } from 'vue-router';
import Container from '@/components/Container.vue';
import Pagination from '@/components/Pagination/index.vue';
import router from '@/router/index';
import ListFilter from './ListFilter.vue';
import ListTable from './ListTable.vue';
import partnerAPI from '@/service/partnerAPI';
import IconBase from '@/components/icons/IconBase.vue';
import CheckCircleIcon from '@/components/icons/CheckCircleIcon.vue';
import getServerErrorMessage from '@/utils/getServerErrorMessage';

export type FeaturedSellerListParams = {
  keyword?: string;
  page: number;
  pageSize: number;
};

export default defineComponent({
  name: 'FeatureSeller',
  components: {
    Container,
    ListFilter,
    ListTable,
    Pagination,
    IconBase,
    CheckCircleIcon
  },
  props: {},
  setup() {
    const route = useRoute();
    const query = route.query;
    const featuredSellerList = ref([])

    const handleRegister = async (featuredSeller) => {
      const featuredSellerId = featuredSeller.featuredSellerId
      const partnerId = featuredSeller.partnerId
      try {
        const { data } = await partnerAPI.adminFeaturedSeller.adminFeaturedSellerUpdate({
          featuredSellerId,
          partnerId
        })
        alert(data.message)
        fetchFeaturedSellerList()
      }catch (e) {
        alert(getServerErrorMessage(e));
      }
    }


    const filterParams = reactive<FeaturedSellerListParams>({
      keyword: (query.keyword as string) || '',
      page: Number(query.page) || 0,
      pageSize: Number(query.pageSize) || 10,
    });

    const sellerList = ref({
      success: true,
      data: [],
      message: '',
      total: 0,
    });

    const handleChangeFilterParams = (key: string, value: any) => {
      filterParams[key] = value;
    };

    const handleChangePage = (page: number) => {
      filterParams.page = page - 1;
      executeSearch();
    };
    const handleChangePageSize = (pageSize: number) => {
      filterParams.page = 0;
      filterParams.pageSize = pageSize;
      executeSearch();
    };
    const handleClickSearch = () => {
      console.log('베르나');
      filterParams.page = 0;
      executeSearch();
    };

    /**
     * 검색 실행
     */
    const executeSearch = () => {
      //fetchSellerList();
      router.push({
        path: window.location.pathname,
        query: {
          keyword: filterParams.keyword || '',
          page: filterParams.page || 0,
          pageSize: filterParams.pageSize || 10,
        },
      });
    };

    const getAppliedFilterParams = () => {
      return {
        keyword: filterParams.keyword,
        page: filterParams.page,
        pageSize: filterParams.pageSize,
      }
    }

    const fetchFeaturedSellerList = async () => {
      try {
        const { data } = await partnerAPI.adminFeaturedSeller.adminFeaturedSellerAll();
        featuredSellerList.value = data.data as any;
      } catch ({ response }) {
        console.error(response.data);
        alert(response.data.message);
      }
    }
    const fetchSellerList = async () => {
      try {
        const { data } = await partnerAPI.adminFeaturedSeller.adminFeaturedSellerPartnerList(
          getAppliedFilterParams()
        );
        sellerList.value = data as any;
      } catch ({ response }) {
        console.error(response.data);
        alert(response.data.message);
      }
    };


    onMounted(() => {
      fetchSellerList()
      fetchFeaturedSellerList()
    });

    watch([filterParams], (newFilter, newIsFilterOn) => {
      console.log('watch filter', newFilter, newIsFilterOn);
      fetchSellerList();
    });

    const handleClickProfile = (partnerId) => {
      console.log('dd')
      window.open(
          `/etc/partner/${partnerId}`,
          '',
          'width=1560, height=800, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=no, location=no'
      )
    }

    return {
      handleRegister,
      filterParams,
      sellerList,
      featuredSellerList,
      handleChangePage,
      handleChangePageSize,
      handleClickSearch,
      executeSearch,
      handleChangeFilterParams,
      fetchSellerList,
      handleClickProfile
    };
  },
});
